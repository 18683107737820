import React from 'react';
import Layout from '../components/Layout';
// import { Link } from 'gatsby';
import { StaticQuery, graphql, Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import SEO from 'components/SEO/SEO';
import logger from 'utils/logger';

import { Container, Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';

import ShareBar from 'components/CTAs/ShareBar';

class ProductLineSingle extends React.Component {
  render () {
    logger.template(__filename, '>', 'render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const data = get(this, 'props.data');
    logger.gql(data);
    if (!data || !data.contentfulProductLine) { return <LoadingView />; }
    let productLine = data.contentfulProductLine;
    // const parsed = querystring.parse(location.search);
    // logger.debug(`querystring`, parsed);

    logger.templateEnd();
    return (
      <Layout>
        <Fluid>
          <SEO title={siteTitle} />
          <section className={`ptb-30em`}>
            <Offset>
              <Row>
                <Col md={4}>
                  <h1>{productLine.name}</h1>
                  <p className="lead"><b>Product Line</b></p>
                </Col>
                <Col md={8}>
                  <pre><code>{JSON.stringify(productLine, null, 2) }</code></pre>
                </Col>
              </Row>
            </Offset>
          </section>
        </Fluid>
      </Layout>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulProductLine(slug: {eq: $slug}) {
      ...ProductLine
    }
  }
`;
// export default ({ children }) => (
//   <StaticQuery
//     query={query}
//     render={data => (
//       <>
//         <Helmet titleTemplate={`%s | ${data.site.siteMetadata.title}`} defaultTitle={data.site.siteMetadata.title} />
//         <div>
//           {children}
//         </div>
//       </>
//     )}
//   />
// );
export default ProductLineSingle;

