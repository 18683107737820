import React from 'react';
import logger from 'utils/logger';
import { Container, Col, Row } from 'reactstrap';

export default class ShareBar extends React.Component {
  render () {
    logger.file(__filename, '>', 'render()');
    const sourceURL = `https%3A//apantac.com`;
    const title = this.props.title;
    const summary = this.props.title;
    const hashtags = this.props.hashtags;
    let urlToShare;
    if (typeof window !== 'undefined') {
      urlToShare = window.location.href;
    }
    const emailBody = `${summary}. Find out more at ${urlToShare}.`;
    logger.data('urlToShare', urlToShare);
    logger.groupEnd();
    return (
      <Container fluid>
        <Row>
          <Col className={`prl-0`}>
            <a href={`http://twitter.com/share?text=${summary}&url=${urlToShare}&hashtags=${hashtags}`} alt={`Share this on Twitter`} className="ss-btn bg-color-twitter color-white">
              <i className="socicon socicon-twitter" />
            </a>
          </Col>
          <Col className={`prl-0`}>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`} alt={`Share this on Facebook`} className="ss-btn bg-color-facebook color-white">
              <i className="socicon socicon-facebook" />
            </a>
          </Col>
          <Col className={`prl-0`}>
            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${urlToShare}&title=${title}&summary=${summary}&source=${sourceURL}`} alt={`Share this on LinkedIn`} className="ss-btn bg-color-linkedin color-white">
              <i className="socicon socicon-linkedin" />
            </a>
          </Col>
          <Col className={`prl-0`}>
            <a href={`mailto:?&subject=${title}&body=${emailBody}`} alt={`Share this via Email`} className="ss-btn bg-color-email color-white">
              <i className="socicon socicon-mail" />
            </a>
          </Col>
        </Row>
      </Container>
    );
  }
}
